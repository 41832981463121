import { createContext, useContext, useState, useRef, useEffect } from "react";
import { fetchAiInputs } from "../Services/api/Repositories/BusinessPlanGenerator";
import { dtoToAiGeneratedContent } from "../models/aiGeneratedContent";

const PageContext = createContext();

export function PageProvider({ children, pageProp }) {
  const [page, setPage] = useState(pageProp);
  const [section, setSection] = useState(pageProp);

  const [isLoading, setLoading] = useState(false);
  const [businessDescription, setBusinessDescription] = useState(null);
  const [businessValues, setBusinessValues] = useState(null);
  const [businessOwnership, setBusinessOwnership] = useState(null);
  const [businessProductsAndServices, setBusinessProductsAndServices] = useState(null);
  const [businessIntellectualProperty, setBusinessIntellectualProperty] = useState(null);

  const [marketProblems, setMarketProblems] = useState(null);
  const [marketSolutions, setMarketSolutions] = useState(null);
  const [marketTargets, setMarketTargets] = useState(null);
  const [marketTrends, setMarketTrends] = useState(null);
  const [marketCustomers, setMarketCustomers] = useState(null);
  const [marketCompetitors, setMarketCompetitors] = useState(null);
  const [marketSellingPropositions, setMarketSellingPropositions] = useState(null);

  const [strategyMarketChannels, setStrategyMarketChannels] = useState(null);
  const [strategyCustomerJourney, setStrategyCustomerJourney] = useState(null);
  const [strategyPricing, setStrategyPricing] = useState(null);
  const [strategySales, setStrategySales] = useState(null);
  const [strategyOperations, setStrategyOperations] = useState(null);
  const [strategyEquipment, setStrategyEquipment] = useState(null);
  const [strategyManagementTeam, setStrategyManagementTeam] = useState(null);
  const [strategyPersonnelTeam, setStrategyPersonnelTeam] = useState(null);

  const [inputs, setInputs] = useState();
  const [options, setOptions] = useState();

  useEffect(() => {
    const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;
    async function fetchData() {
      try {
        setLoading(true);
        const response = await fetchAiInputs({
          bpScenarioId,
        });
        const dto = dtoToAiGeneratedContent(response.SectionData);

        setInputs(dto);
        setOptions(dto.options);

        console.log("dto", dto, JSON.parse(response.SectionData));

        setBusinessDescription(dto.businessDescription);
        setBusinessValues(dto.businessValues);
        setBusinessOwnership(dto.businessOwnership);
        setBusinessProductsAndServices(dto.businessProductsAndServices);
        setBusinessIntellectualProperty(dto.businessIntellectualProperty);

        setMarketProblems(dto.marketProblems);
        setMarketSolutions(dto.marketSolutions);
        setMarketTargets(dto.marketTargets);
        setMarketTrends(dto.marketTrends);
        setMarketCustomers(dto.marketCustomers);
        setMarketCompetitors(dto.marketCompetitors);
        setMarketSellingPropositions(dto.marketSellingPropositions);

        setStrategyMarketChannels(dto.strategyMarketChannels);
        setStrategyCustomerJourney(dto.strategyCustomerJourney);
        setStrategyPricing(dto.strategyPricing);
        setStrategySales(dto.strategySales);
        setStrategyOperations(dto.strategyOperations);
        setStrategyEquipment(dto.strategyEquipment);
        setStrategyManagementTeam(dto.strategyManagementTeam);
        setStrategyPersonnelTeam(dto.strategyPersonnelTeam);
      } catch (error) {
        console.error("Error fetching business plan scenario:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const stateRef = useRef();

  stateRef.current = {
    businessDescription,
    businessValues,
    businessOwnership,
    businessProductsAndServices,
    businessIntellectualProperty,
    marketProblems,
    marketSolutions,
    marketTargets,
    marketTrends,
    marketCustomers,
    marketCompetitors,
    marketSellingPropositions,
    strategyMarketChannels,
    strategyCustomerJourney,
    strategyPricing,
    strategySales,
    strategyOperations,
    strategyEquipment,
    strategyManagementTeam,
    strategyPersonnelTeam,
  };

  function getGeneratedData() {
    return stateRef.current;
  }

  const contextValue = {
    inputs,
    page,
    setPage,
    businessDescription,
    setBusinessDescription,
    businessValues,
    setBusinessValues,
    businessOwnership,
    setBusinessOwnership,
    businessProductsAndServices,
    setBusinessProductsAndServices,
    businessIntellectualProperty,
    setBusinessIntellectualProperty,
    marketProblems,
    setMarketProblems,
    marketSolutions,
    setMarketSolutions,
    marketTargets,
    setMarketTargets,
    marketTrends,
    setMarketTrends,
    marketCustomers,
    setMarketCustomers,
    marketCompetitors,
    setMarketCompetitors,
    marketSellingPropositions,
    setMarketSellingPropositions,
    strategyMarketChannels,
    setStrategyMarketChannels,
    strategyCustomerJourney,
    setStrategyCustomerJourney,
    strategyPricing,
    setStrategyPricing,
    strategySales,
    setStrategySales,
    strategyOperations,
    setStrategyOperations,
    strategyEquipment,
    setStrategyEquipment,
    strategyManagementTeam,
    setStrategyManagementTeam,
    strategyPersonnelTeam,
    setStrategyPersonnelTeam,
    options,
    getGeneratedData,
    isLoading,
    setLoading,
    section,
    setSection,
  };

  return <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>;
}

export function usePage() {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error("usePage must be used within a PageProvider");
  }
  return context;
}
