import { twMerge } from "tailwind-merge";

export function Input(props) {
  const {
    placeholder,
    type = "text",
    className,
    value = "",
    onChange,
    onKeyPress,
    title,
    error,
  } = props;

  const style = twMerge(
    `border rounded-md w-full h-full text-18 p-2 focus:outline-none focus:border-primary min-h-16 ${error ? "!border-red-primary" : "border-grey-light-3"}`,
    className,
  );

  return (
    <div className="flex flex-col gap-2 h-full">
      {title && <span className="text-18 font-bold">{title}</span>}
      <input
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        type={type}
        placeholder={placeholder}
        className={style}
      />
    </div>
  );
}
