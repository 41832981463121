import { Link } from "react-router-dom";
import { BusinessPlanCard } from "./components/BusinessPlanCard";
import { cards } from "./config";
import { Button } from "./components/CopiedFromRefactor/Button";
import PitchScenarioMenu from "../../SlideEditor/components/components/menus/PitchScenarioMenu/PitchScenarioMenu";

export function AiGenerator() {
  return (
    <div className="flex flex-col items-center gap-20 p-4 bg-white">
      <div className="flex flex-col items-center gap-6">
        <span className="text-60 font-semibold text-center">Create your business plan with AI</span>
        <span className="text-18 max-w-screen-xl text-center">
          Your business plan is organized into chapters, with each chapter containing several
          sections. To complete your plan, navigate through each chapter and fill in the
          corresponding sections. Be sure to finish the &quot;Executive Summary&quot; chapter last.
          You can also customize the cover page as needed.
        </span>
      </div>

      <div className="flex flex-col items-center justify-center gap-16">
        <div className="flex flex-col gap-3 w-full max-w-[1700px]">
          <PitchScenarioMenu
            menuButton={
              <span className="cursor-pointer text-grey-light-3 text-32 flex gap-3 items-center w-fit pr-2">
                Plan Name: Modeliks plan <img src="/icons/icon-arrow.svg" />
              </span>
            }
            onScenarioChange={() => {}}
            setSaving={() => {}}
            saveCurrentSection={() => {}}
          />
          <div className="flex flex-wrap justify-center gap-6 grid-cols-5">
            {cards.map((card, index) => {
              return (
                <BusinessPlanCard
                  url={card.url}
                  icon={card.icon}
                  sections={card.sections}
                  title={card.name}
                  key={`business-card-${index}`}
                  index={index + 1}
                />
              );
            })}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-12 max-w-screen-md">
          <Link to="/business_plan/ai_generator/business_overview/description">
            <Button className={"min-w-96 py-4"} rounded>
              <span className="text-24">Start your business plan</span>
            </Button>
          </Link>
          <Link to="/business_plan">
            <Button className={"min-w-96 py-4"} outline rounded size="large">
              <span className="text-24"> View plan</span>
            </Button>
          </Link>
        </div>
        <Link className="text-primary underline font-bold">Share & Downloads Plan</Link>
      </div>
    </div>
  );
}
