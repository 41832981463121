import { useState, useEffect, useRef } from "react";
import { Button } from "./CopiedFromRefactor/Button";

export function CardSelect(props) {
  const { isSelected, children, onClick, value, onSaveEdit, index, nonEditable } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [inputValues, setInputValues] = useState(Array.isArray(value) ? value : [value]);
  const inputRefs = useRef([]);

  const isSelectedStyles = isSelected
    ? "text-white bg-primary hover:bg-primary hover:text-white"
    : "text-black-primary bg-white hover:bg-blue-light-4 hover:text-black-primary";

  useEffect(() => {
    if (isEditing && inputRefs.current[0]) {
      inputRefs.current[0].focus();
      inputRefs.current[0].select();
    }
  }, [isEditing]);

  useEffect(() => {
    setInputValues(Array.isArray(value) ? value : [value]);
  }, [value]);

  function onCardClick() {
    if (!isEditing) onClick(value);
  }

  function onEditClick(e) {
    e.stopPropagation();
    setIsEditing(true);
  }

  function onSaveClick() {
    setIsEditing(false);
    if (inputValues.length > 1) {
      onSaveEdit(index, inputValues);
    } else {
      onSaveEdit(index, inputValues[0]);
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      onSaveClick();
    }
  }

  function handleChange(e, idx) {
    const updatedValues = [...inputValues];
    updatedValues[idx] = e.target.value;
    setInputValues(updatedValues);
  }

  return (
    <div
      onClick={onCardClick}
      className={`py-8 px-2 text-center flex items-center justify-center h-full rounded-md relative cursor-pointer border border-primary hover:border-primary hover:shadow-light ${isSelectedStyles}`}
    >
      {!nonEditable && (
        <>
          {isEditing ? (
            <Button outline size="small" className="absolute top-2 right-2" onClick={onSaveClick}>
              Save
            </Button>
          ) : (
            <Button size="small" className="absolute top-2 right-2" onClick={(e) => onEditClick(e)}>
              Edit
            </Button>
          )}
        </>
      )}

      {isEditing ? (
        <div className={`flex items-center gap-3 w-full`}>
          {inputValues.map((inputValue, idx) => (
            <input
              placeholder="..."
              key={`input-${idx}`}
              ref={(el) => (inputRefs.current[idx] = el)}
              className={`w-full bg-transparent focus:outline-none border-b border-grey-light-3`}
              value={inputValue}
              onChange={(e) => handleChange(e, idx)}
              onKeyDown={handleKeyDown}
            />
          ))}
        </div>
      ) : (
        <span className={"text-18"}>{children}</span>
      )}
    </div>
  );
}
