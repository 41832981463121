import { Link } from "react-router-dom";
import { Button } from "./CopiedFromRefactor/Button";

export function FormNavigation(props) {
  const { onSave } = props;

  return (
    <div className="w-full flex justify-end gap-6 border-t-2 border-grey-light p-4 mt-auto">
      <Link to="/business_plan/ai_generator">
        <Button className="w-38" size="large" rounded outline>
          Back
        </Button>
      </Link>
      <Button className="w-38" size="large" rounded onClick={onSave}>
        Save
      </Button>
    </div>
  );
}
