import { useEffect, useState } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import { marketCustomersObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";

const MAX_CARDS = 5;

export function TargetCustomers() {
  const navigate = useNavigate();
  const {
    section,
    marketCustomers,
    setMarketCustomers,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection } = useSave(sectionName);

  const [cards, setCards] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      title: item,
      isSelected: selectedItems.includes(item),
    }));

    selectedItems.forEach((selectedItem) => {
      if (!items.includes(selectedItem)) {
        allItems.push({ title: selectedItem, isSelected: true });
      }
    });

    return allItems;
  }

  async function fetchCustomers() {
    try {
      setLoadingCustomers(true);
      const customersResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "buyerPersonas",
      });
      setCards(transformToSelectable(customersResponse.SectionData.Options.buyerPersonas));
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoadingCustomers(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;

    if (marketCustomers) {
      if (marketCustomers.buyerPersonas) {
        setCards((prev) =>
          transformToSelectable(options?.buyerPersonas || prev, marketCustomers.buyerPersonas),
        );
      }
    }

    if (options) {
      if (!options.buyerPersonas) {
        fetchCustomers();
      } else {
        setCards(transformToSelectable(options.buyerPersonas, marketCustomers.buyerPersonas));
      }
    }
  }, [options, marketCustomers, isLoading]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    const cardExists = cards.some((card) => card.title === trimmedValue);
    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { title: trimmedValue, isSelected: false };
    setCards((prevCards) => [...prevCards, newCard]);
    setInputValue("");
    setIsInputActive(false);
    setInputError(false);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      addCard();
    }
  }

  function onCardClick(index) {
    const selectedCount = cards.filter((card) => card.isSelected).length;

    setCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index
          ? {
              ...card,
              isSelected: card.isSelected ? false : selectedCount < MAX_CARDS,
            }
          : card,
      ),
    );
  }

  function handleSaveEdit(index, newTitle) {
    setCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
    );
  }

  function validate() {
    return cards.filter((card) => card.isSelected).length > 0;
  }

  function onNextHandle() {
    setMarketCustomers({
      buyerPersonas: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });
    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        await nextSection(marketCustomersObjectToAiGeneratedContentDto(generatedData, sectionName));
      } catch (error) {
        console.error("Error during business plan generation:", error);
      }
    });
    navigate("/business_plan/ai_generator/market_analysis/competitors");
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    setMarketCustomers({
      buyerPersonas: cards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });
    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        await generateSection(
          marketCustomersObjectToAiGeneratedContentDto(generatedData, sectionName),
        );
      } catch (error) {
        console.error("Error during business plan generation:", error);
      }
    });
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={cards.some((card) => card.isSelected)}
        question={"Who are your target customers?"}
        description={"Define the buyer personas for your business."}
        maxSelectiveOptions={5}
        onRegenerateOptions={fetchCustomers}
      >
        {loadingCustomers ? (
          <CircleLoader />
        ) : (
          <div className="grid grid-cols-2 gap-3 auto-rows-fr">
            {cards.map((card, index) => (
              <CardSelect
                value={card.title}
                onClick={() => onCardClick(index)}
                key={`select-card-${index}`}
                isSelected={card.isSelected}
                onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue)}
                index={index}
              >
                {card.title}
              </CardSelect>
            ))}

            {isInputActive && (
              <Input
                error={inputError}
                placeholder="Add new buyer persona"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
            )}
          </div>
        )}

        <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
          Add More
        </Button>
      </QuestionWrapper>

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />
    </>
  );
}
