import { useState } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";

const initialCards = [
  { title: "Critical success factor 1", isSelected: false },
  { title: "Critical success factor 2", isSelected: false },
  { title: "Critical success factor 3", isSelected: false },
  { title: "Critical success factor 4", isSelected: false },
];

export function CriticalSuccessFactor() {
  const [cards, setCards] = useState(initialCards);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) {
      setInputError(true);
      return;
    }

    const cardExists = cards.some((card) => card.title === trimmedValue);
    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { title: trimmedValue, isSelected: false };
    setCards((prevCards) => [...prevCards, newCard]);
    setInputValue("");
    setIsInputActive(false);
    setInputError(false);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      addCard();
    }
  }

  function onCardClick(index) {
    setCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, isSelected: !card.isSelected } : card)),
    );
  }

  function updateCard(index, value) {
    const trimmedValue = value.trim();
    if (!trimmedValue) return;

    const cardExists = cards.some((card, i) => card.title === trimmedValue && i !== index);
    if (cardExists) {
      setInputError(true);
      return;
    }

    setCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, title: trimmedValue } : card)),
    );
  }

  function validate() {
    return cards.some((card) => card.isSelected);
  }

  const data = {
    salesChannels: cards.filter((card) => card.isSelected).map((card) => card.title),
  };

  return (
    <>
      <QuestionWrapper
        isAnswered={validate()}
        question={"What are your Unique Selling Points?"}
        description={
          "The unique selling points define what makes your business different and better than your competitors. "
        }
        hasSelectOptions
        onRegenerateOptions={() => console.log("regenerate")}
      >
        <div className="grid grid-cols-1 gap-3 auto-rows-fr">
          {cards.map((card, index) => (
            <CardSelect
              onClick={() => onCardClick(index)}
              index={index}
              key={`select-card-${index}`}
              isSelected={card.isSelected}
              onSaveEdit={updateCard}
              value={card.title}
            >
              {card.title}
            </CardSelect>
          ))}

          {isInputActive && (
            <Input
              error={inputError}
              placeholder="Enter marketing channels"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
          )}
        </div>

        <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
          Add More
        </Button>
      </QuestionWrapper>

      <SectionButtons />
    </>
  );
}
