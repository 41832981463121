import { AiGeneratedContent } from "./AiGeneratedContent";
import { AiGeneratedContentDto } from "./AiGeneratedContentDto";

export function dtoToAiGeneratedContent(dto) {
  const json = JSON.parse(dto);
  const jsonInputs = json.Inputs;
  const options = json.Options;

  const businessDescription = {
    companyName: jsonInputs.companyName,
    industryName: jsonInputs.industryName,
    city: jsonInputs.city,
    state: jsonInputs.state,
    country: jsonInputs.country,
    monthEstablished: jsonInputs.monthEstablished,
    yearEstablished: jsonInputs.yearEstablished,
  };

  const businessValues = {
    companyValues: jsonInputs.companyValues,
  };

  const businessOwnership = {
    shareholders: jsonInputs.shareholders,
    companyStructureType: jsonInputs.companyStructureType,
  };

  const businessProductsAndServices = {
    products: jsonInputs.products,
    services: jsonInputs.services,
  };

  const businessIntellectualProperty = {
    intellectualProperties: jsonInputs.intellectualProperties,
  };

  const marketProblems = {
    problems: jsonInputs.problems,
  };

  const marketSolutions = {
    solutions: jsonInputs.solutions,
  };

  const marketTargets = {
    targetMarketGeography: jsonInputs.targetMarketGeography,
    targetMarketType: jsonInputs.targetMarketType,
  };

  const marketTrends = {
    marketTrends: jsonInputs.marketTrends,
  };

  const marketCustomers = {
    buyerPersonas: jsonInputs.buyerPersonas,
  };

  const marketCompetitors = {
    competitors: jsonInputs.competitors,
  };

  const marketSellingPropositions = {
    keySellingPoints: jsonInputs.keySellingPoints,
  };

  const strategyMarketChannels = {
    salesChannels: jsonInputs.salesChannels,
  };

  const strategyCustomerJourney = {
    customerJourneyStages: jsonInputs.customerJourneyStages,
  };

  const strategyPricing = {
    pricingStrategies: jsonInputs.pricingStrategies,
  };

  const strategySales = {
    salesChannels: jsonInputs.salesChannels,
  };

  const strategyOperations = {
    facilities: jsonInputs.facilities,
  };

  const strategyEquipment = {
    equipments: jsonInputs.equipments,
  };

  const strategyManagementTeam = {
    topManagers: jsonInputs.topManagers,
  };

  const strategyPersonnelTeam = {
    keyPersonnel: jsonInputs.keyPersonnel,
  };

  return AiGeneratedContent({
    businessDescription,
    businessValues,
    businessOwnership,
    businessProductsAndServices,
    businessIntellectualProperty,
    marketProblems,
    marketSolutions,
    marketTargets,
    marketTrends,
    marketCustomers,
    marketCompetitors,
    marketSellingPropositions,
    strategyMarketChannels,
    strategyCustomerJourney,
    strategyPricing,
    strategySales,
    strategyOperations,
    strategyEquipment,
    strategyManagementTeam,
    strategyPersonnelTeam,
    options,
  });
}

function getGlobalIdentifiers() {
  return {
    account: global.Modeliks.Account.ID,
    company: global.Modeliks.CompanyInfo.ID,
    user: global.Modeliks.User.ID,
    bpScenarioId: global.Modeliks.BusinessPlanScenarioInfo.ID,
  };
}

export function businessDescriptionObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { businessDescription } = generatedData;
  const { industryName, companyName, city, state, country, monthEstablished, yearEstablished } =
    businessDescription;

  const inputs = {
    industryName,
    companyName,
    city,
    state,
    country,
    monthEstablished,
    yearEstablished,
  };
  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function businessValuesObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { businessValues } = generatedData;
  const { companyValues } = businessValues;

  const inputs = {
    companyValues,
  };
  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function businessOwnershipObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { businessOwnership } = generatedData;
  const { shareholders, companyStructureType } = businessOwnership;

  const inputs = {
    shareholders,
    companyStructureType,
  };
  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function businessProductsAndServicesObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { businessProductsAndServices } = generatedData;
  const { products, services } = businessProductsAndServices;

  const inputs = {
    products,
    services,
  };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function businessIntellectualPropertyObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { businessIntellectualProperty } = generatedData;
  const { intellectualProperties } = businessIntellectualProperty;

  const inputs = { intellectualProperties };

  console.log("inputs", inputs);

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketProblemsObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();
  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { marketProblems } = generatedData;
  const { problems } = marketProblems;

  const inputs = { problems };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketSolutionsObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { marketSolutions } = generatedData;
  const { solutions } = marketSolutions;

  const inputs = { solutions };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketTargetsObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { marketTargets } = generatedData;
  const { targetMarketGeography, targetMarketType } = marketTargets;

  const inputs = { targetMarketGeography, targetMarketType };

  console.log("inputs", inputs);

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketTrendsObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { marketTrends } = generatedData;

  const inputs = { marketTrends: marketTrends.marketTrends };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketCustomersObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { marketCustomers } = generatedData;
  const { buyerPersonas } = marketCustomers;

  const inputs = { buyerPersonas };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketCompetitorsObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { marketCompetitors } = generatedData;
  const { competitors } = marketCompetitors;

  const inputs = { competitors };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function marketSellingPorpositionsObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { marketSellingPropositions } = generatedData;
  const { keySellingPoints } = marketSellingPropositions;

  const inputs = { keySellingPoints };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyMarketChannelsObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { strategyMarketChannels } = generatedData;
  const { salesChannels } = strategyMarketChannels;

  const inputs = { salesChannels };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyCustomerJourneyObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { strategyCustomerJourney } = generatedData;
  const { customerJourneyStages } = strategyCustomerJourney;

  const inputs = { customerJourneyStages };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyPricingObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { strategyPricing } = generatedData;
  const { pricingStrategies } = strategyPricing;

  const inputs = { pricingStrategies };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategySalesObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { strategySales } = generatedData;
  const { salesChannels } = strategySales;

  const inputs = { salesChannels };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyOperationsObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { strategyOperations } = generatedData;
  const { facilities } = strategyOperations;

  const inputs = { facilities };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyEquipmentObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { strategyEquipment } = generatedData;
  const { equipments } = strategyEquipment;

  const inputs = { equipments };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyManagementTeamObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { strategyManagementTeam } = generatedData;
  const { topManagers } = strategyManagementTeam;

  const inputs = { topManagers };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}

export function strategyPersonellTeamObjectToAiGeneratedContentDto(generatedData, options) {
  const { account, company, user, bpScenarioId } = getGlobalIdentifiers();

  // const { sectionOrder, pageOrder, pageName, shouldGenerate } = options;

  const { strategyPersonnelTeam } = generatedData;
  const { keyPersonnel } = strategyPersonnelTeam;

  const inputs = { keyPersonnel };

  return AiGeneratedContentDto(inputs, bpScenarioId);
}
