import { useNavigate } from "react-router-dom";
import { Button } from "./CopiedFromRefactor/Button";
import { usePage } from "../contexts/PageProvider";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";

export function BusinessPlanCard(props) {
  const { businessDescription, inputs, isLoading } = usePage();
  const { index, title, sections, selectedSection, url, icon, isFormCard } = props;
  const navigate = useNavigate();

  function areAllValuesFilled(obj) {
    if (obj === null) return false;
    return Object.values(obj).every(
      (value) => value !== null && value !== undefined && value !== "",
    );
  }

  function isEdited(propertyName) {
    if (!inputs) return false;

    if (Array.isArray(propertyName)) {
      return propertyName.some((name) => {
        const value = inputs[name];
        return value !== undefined && value !== null && checkValues(value);
      });
    }

    const value = inputs[propertyName];
    return value !== undefined && value !== null && checkValues(value);
  }

  function checkValues(value) {
    if (typeof value === "object" && value !== null) {
      return Object.values(value).some((val) => val !== undefined && val !== null);
    }
    return true;
  }

  function handleNavigate(path) {
    navigate(path);
  }

  return (
    <div
      className={`flex flex-col shadow ${isFormCard ? "sticky min-w-92" : "min-w-80"} top-12 min-h-88`}
    >
      <div className="bg-blue-light-4 max-h-32 flex items-center gap-4 px-6 min-h-24 shadow">
        <img className="max-h-14" src={`/icons/icon-${index}.svg`} />
        <span className="font-bold max-w-40 text-20 lg:text-text-24 xl:text-32">{title}</span>
      </div>
      {isLoading && !isFormCard ? (
        <div className="grow flex items-center justify-center">
          <CircleLoader />
        </div>
      ) : (
        <>
          {icon ? (
            <div
              onClick={() => handleNavigate("/forecast")}
              className="cursor-pointer border shadow border-primary rounded-md max-w-32 flex flex-col gap-2 items-center p-4 m-auto hover:bg-blue-light-4"
            >
              <img src={`/icons/${icon.icon}.svg`} />
              <span className="text-primary text-center font-bold">{icon.text}</span>
            </div>
          ) : (
            <div className="flex flex-col py-4 grow">
              {sections?.map((section, sectionIndex) => (
                <div key={`plan-card-${sectionIndex}`} className="flex flex-col">
                  <div
                    className={`py-2 flex justify-between items-center gap-2 ${selectedSection === section.title || (section.subsections && section.subsections.some((subsection) => selectedSection === subsection.title)) ? "bg-blue-light-4" : ""} px-5 py-1`}
                  >
                    <div className="flex items-center text-18 gap-1">
                      <span className="text-primary w-7">{`${index}.${sectionIndex + 1}`}</span>
                      <span>{section.title}</span>
                    </div>

                    <Button
                      disabled={
                        section.title !== "Description" && !areAllValuesFilled(businessDescription)
                      }
                      className="min-w-12"
                      outline={
                        isEdited(section.name) &&
                        !(
                          section.title !== "Description" &&
                          !areAllValuesFilled(businessDescription)
                        )
                      }
                      size="small"
                      onClick={() =>
                        handleNavigate(`/business_plan/ai_generator/${url}/${section.url}`)
                      }
                    >
                      {isEdited(section.name) ? "Edit" : "Start"}
                    </Button>
                  </div>
                  {section.subsections && isFormCard && (
                    <div className="flex flex-col">
                      {section.subsections.map((subsection, index) => (
                        <div
                          key={`subsection-${sectionIndex}-${index}`}
                          className={`flex justify-between items-center gap-2 ${selectedSection === subsection.title ? "bg-blue-light-4" : ""} pr-5 pl-[54px] py-1`}
                        >
                          <div className="flex items-center gap-2">
                            <div className="w-1.5 h-1.5 bg-primary rounded-full"></div>
                            <span className="text-18">{subsection.title}</span>
                          </div>

                          <Button
                            outline={isEdited(subsection.name)}
                            className="min-w-12"
                            size="small"
                            onClick={() =>
                              handleNavigate(`/business_plan/ai_generator/${url}/${subsection.url}`)
                            }
                          >
                            {isEdited(subsection.name) ? "Edit" : "Start"}
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
