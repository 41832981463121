import React from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Button from "@mui/material/Button";
import { default as ButtonJ } from "../../../../../components/actions/Button";
import { ArrowRight, SmallPlusIcon, TrashCan } from "../../../../../components/icons/svgIcons";
import { Theme } from "../../../../../theme/styles/theme";
import { AddDialogTitle } from "../../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import AddDialog from "../../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogContent } from "../../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import Input from "../../../../../components/actions/Input";
import CircleCheckboxM from "../../../../../components/actions/CircleCheckboxM";
import MaterialSelect from "../../../../../components/actions/SelectM";
import { DialogActions } from "@mui/material";
import CancelDialog from "../../../../../components/dialogs/settings/CancelDialog";
import PitchScenarioLimitDialog from "../../Dialog/PitchScenarioLimitDialog";
import IconButton from "@mui/material/IconButton";
import { EditIcon } from "../../menus/LeftMenu/components/Icons/LeftMenuIcons";
import request from "superagent";
import "./pitchScenarioMenu.scss";
import NoAccessDashboards from "../../../../../pages/Secure/Dashboards/dashboard_noaccess.png";
import { AccessControl } from "../../../../../data/Permissions/AccessControl";
import Tooltip from "@mui/material/Tooltip";

const Dialog = (props) => {
  return (
    <AddDialog onClose={props.onClose} open>
      <AddDialogTitle title={props.title} onClose={props.onClose} />
      <AddDialogContent>
        <div className="scenario_add_content">
          <div className="sad_heading">{props.dialogPurpose}</div>
          {props.children}
        </div>
      </AddDialogContent>
      <DialogActions>
        <ButtonJ
          color={"primary"}
          backgroundColor={"primary"}
          width="91px"
          padding="0 0 0 0"
          label={props.actionButtonLabel}
          onClick={props.onActionClick}
        />
      </DialogActions>
    </AddDialog>
  );
};

class PitchScenarioMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editedScenarioName: "",
      scenarioName: "",
      dialogOpen: false,
      editDialogOpen: false,
      scenarioNameError: "",

      cancelDialog: false,
      openScenarioLimit: false,
      newScenario: false,
      industryScenario: false,
      selectedIndustry: global.Modeliks.PitchIndustries.length
        ? global.Modeliks.PitchIndustries[0].ID
        : null,
      scenarioForDuplicate: global.Modeliks.PitchScenarios[0].ID,
    };

    this.scenarioForDeletion = null;
    this.scenarioForEdit = null;
  }

  setScenarioForEdit = (scenario) => {
    this.scenarioForEdit = scenario;
    this.setState({ editDialogOpen: true, editedScenarioName: scenario.Name });
  };

  addNewScenario = () => {
    if (this.validate()) {
      {
        this.props.saveCurrentSection(() => {
          this.setState({ dialogOpen: false, scenarioName: "" });
          this.props.setSaving(true);
          if (global.Modeliks.PitchScenarios.length < 3) {
            if (this.state.newScenario || this.state.industryScenario) {
              global.Modeliks.post(
                "pitchscenarios",
                {
                  Company_ID: global.Modeliks.CompanyInfo.ID,
                  Name: this.state.scenarioName,
                  IndustryID: this.state.industryScenario ? this.state.selectedIndustry : null,
                },
                (pitchScenario) => {
                  request
                    .post("/api/PitchScenarios")
                    .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                    .query({
                      pitch_scenario_id: pitchScenario.id,
                      company_id: global.Modeliks.CompanyInfo.ID,
                      industry_id: this.state.industryScenario ? this.state.selectedIndustry : null,
                    })
                    .then((data) => {
                      global.Modeliks.GetCompanyPitchScenarios(() => {
                        this.handleScenarioChange(pitchScenario.id);
                      });
                    });
                },
                (err) => {
                  this.props.setSaving(false);
                  console.error("err", err);
                },
              );
            } else {
              request
                .post("/api/clonePitchScenario")
                .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                .query({
                  PitchScenario_ID: this.state.scenarioForDuplicate,
                  ScenarioName: this.state.scenarioName,
                })
                .then((data) => {
                  global.Modeliks.GetCompanyPitchScenarios(() => {
                    this.handleScenarioChange(data.body.id);
                  });
                });
            }
          } else {
            this.props.setSaving(false);
            this.setState({ openScenarioLimit: true });
          }
        });
      }
    }
  };

  deletePitchScenario = (ID, scenario) => {
    this.scenarioForDeletion = scenario;
    this.setState({ cancelDialog: true });
  };

  handleDeletePitchScenario = (callBack) => {
    if (global.Modeliks.PitchScenarios.length > 1) {
      let scenIndex = global.Modeliks.PitchScenarios.findIndex(
        (c) => c == this.scenarioForDeletion,
      );

      if (scenIndex == -1) {
        return;
      }

      global.Modeliks.del(
        "pitchscenarios",
        {
          ID: this.scenarioForDeletion.ID,
          Company_ID: global.Modeliks.CompanyInfo.ID,
        },
        (data) => {
          global.Modeliks.PitchScenarios.splice(scenIndex, 1);
          if (this.scenarioForDeletion.ID == global.Modeliks.PitchScenarioInfo.ID) {
            this.handleScenarioChange(global.Modeliks.PitchScenarios[0].ID);
          }
          // global.Modeliks.GetCompanyPitchScenarios();
          callBack();
        },
        (err) => {
          console.error("err", err);
        },
      );
    }
  };

  saveEditedScenario = () => {
    if (this.validate()) {
      this.scenarioForEdit.Name = this.state.editedScenarioName;

      this.scenarioForEdit.Save((success, error) => {
        this.scenarioForEdit = null;
        this.state.editedScenarioName = "";
        this.setState({ editDialogOpen: false, editedScenarioName: "" });
      });
    }
  };

  validate = () => {
    let key = "scenarioName";
    if (this.state.editDialogOpen) {
      key = ["editedScenarioName"];
    }
    if (this.state[key]) {
      return true;
    } else {
      this.setState({ scenarioNameError: "please type a scenario name" });
      return false;
    }
  };

  handleScenarioChange = (ID) => {
    this.props.onScenarioChange(ID);
  };

  getColorObj = () => {
    if (global.Modeliks.PERMISSIONS.Pitch.restrictions.edit.active) {
      return {
        color: Theme.colors.primary.blue[150],
      };
    }
    return {};
  };

  getPitchDeckCheckBoxes = () => {
    return (
      <>
        <CircleCheckboxM
          checked={!this.state.newScenario && !this.state.industryScenario}
          onChange={() => this.setState({ newScenario: false, industryScenario: false })}
          size="medium"
          label="Duplicate an existing pitch deck"
        />
        <CircleCheckboxM
          checked={this.state.newScenario && !this.state.industryScenario}
          onChange={() => this.setState({ newScenario: true, industryScenario: false })}
          size="medium"
          label="Create a new pitch deck"
        />
      </>
    );
  };

  getPitchDeckIndustryBox = (disabled = false) => {
    return (
      <CircleCheckboxM
        checked={this.state.industryScenario}
        disabled={disabled}
        onChange={(e) =>
          disabled
            ? e.preventDefault()
            : this.setState({ newScenario: true, industryScenario: true })
        }
        size="medium"
        label="Select industry pitch deck template"
      />
    );
  };

  render() {
    return (
      <>
        <Menu
          className={"categories_menu pitch_scenario_menu"}
          direction={"right"}
          menuButton={
            this.props.menuButton ? (
              this.props.menuButton
            ) : (
              <Tooltip title={global.Modeliks.PitchScenarioInfo.Name} arrow>
                <Button
                  variant="text"
                  className="section_name button"
                  endIcon={<ArrowRight color={"#000"} />}
                  disableElevation
                >
                  <span>{global.Modeliks.PitchScenarioInfo.Name}</span>
                </Button>
              </Tooltip>
            )
          }
          transition
        >
          {global.Modeliks.PitchScenarios &&
            global.Modeliks.PitchScenarios.map((scenario, index) => {
              return (
                <MenuItem
                  key={"left_menu_scenarios_" + index}
                  className={global.Modeliks.PitchScenarioInfo.ID === scenario.ID ? "selected" : ""}
                  onClick={() => this.handleScenarioChange(scenario.ID)}
                >
                  {scenario.Name}
                  <span>
                    <IconButton
                      className={"edit_icon"}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setScenarioForEdit(scenario);
                      }}
                      disabled={!global.Modeliks.PERMISSIONS.Pitch.restrictions.edit.active}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className={"trash_can"}
                      disabled={!global.Modeliks.PERMISSIONS.Pitch.restrictions.edit.active}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.deletePitchScenario(scenario.ID, scenario);
                      }}
                    >
                      <TrashCan />
                    </IconButton>
                  </span>
                </MenuItem>
              );
            })}
          <MenuItem
            disabled={!global.Modeliks.PERMISSIONS.Pitch.restrictions.edit.active}
            onClick={() => {
              if (global.Modeliks.PitchScenarios.length < 3) {
                this.setState({ dialogOpen: true });
              } else {
                this.setState({ openScenarioLimit: true });
              }
            }}
            className={"add_btn"}
            style={{ fontWeight: 600, ...this.getColorObj() }}
          >
            Add New Pitch Deck
            <SmallPlusIcon />
          </MenuItem>
        </Menu>

        {this.state.dialogOpen && (
          <Dialog
            onClose={() => this.setState({ dialogOpen: false, scenarioName: "" })}
            title={"Add New Pitch Deck"}
            actionButtonLabel={"Create"}
            onActionClick={() => this.addNewScenario()}
            dialogPurpose={"Name of Pitch Deck"}
          >
            <div className="sad_input">
              <Input
                placeholder="Please enter..."
                value={this.state.scenarioName}
                error={!!this.state.scenarioNameError}
                errorMessage={this.state.scenarioNameError}
                onChange={(event) => this.setState({ scenarioName: event.target.value })}
              />
            </div>
            <div className="sad_heading">Please select one of the following:</div>

            <div style={{ width: "60%" }}>
              <AccessControl
                allowedPermissions={[
                  {
                    [global.Modeliks.PERMISSIONS.Pitch.key]:
                      global.Modeliks.PERMISSIONS.Pitch.restrictions.edit.key,
                  },
                ]}
                renderNoAccess={
                  <>
                    {this.getPitchDeckCheckBoxes()}
                    {this.getPitchDeckIndustryBox(true)}
                  </>
                }
              >
                {this.getPitchDeckCheckBoxes()}
                {this.getPitchDeckIndustryBox(false)}
              </AccessControl>
            </div>
            <div className={""}>
              {this.state.newScenario ? "" : "Which pitch deck do you want to duplicate?"}
            </div>
            {!this.state.newScenario && (
              <MaterialSelect
                onChange={(e) => this.setState({ scenarioForDuplicate: e.target.value })}
                value={this.state.scenarioForDuplicate}
                options={global.Modeliks.PitchScenarios.map((scenario) => {
                  return {
                    value: scenario.ID,
                    description: scenario.Name,
                  };
                })}
              />
            )}
            <div className={""}>{!this.state.industryScenario ? "" : "Select pitch industry:"}</div>
            {this.state.industryScenario && (
              <MaterialSelect
                onChange={(e) => this.setState({ selectedIndustry: e.target.value })}
                value={this.state.selectedIndustry}
                options={global.Modeliks.PitchIndustries.map((industry) => {
                  return {
                    value: industry.ID,
                    description: industry.Name,
                  };
                })}
              />
            )}
          </Dialog>
        )}

        {this.state.editDialogOpen && (
          <Dialog
            onClose={() => this.setState({ editDialogOpen: false, editedScenarioName: "" })}
            title={"Edit Deck"}
            actionButtonLabel={"Save"}
            onActionClick={() => this.saveEditedScenario()}
            dialogPurpose={"Change Name of Deck"}
          >
            <div className="scenario_add_content">
              <div className="sad_input">
                <Input
                  placeholder="Please enter..."
                  value={this.state.editedScenarioName}
                  error={!!this.state.scenarioNameError}
                  errorMessage={this.state.scenarioNameError}
                  onChange={(event) => {
                    this.setState({ editedScenarioName: event.target.value });
                  }}
                />
              </div>
            </div>
          </Dialog>
        )}
        {this.state.cancelDialog && (
          <CancelDialog
            firstBtnName={"Go Back"}
            btnName={"Delete Scenario"}
            onExit={() => this.setState({ cancelDialog: false })}
            onDelete={() =>
              this.handleDeletePitchScenario(() => this.setState({ cancelDialog: false }))
            }
            title={`Are you sure you want to delete ${this.scenarioForDeletion.Name}?`}
            open={true}
          >
            This action is irreversible. All added data will be lost.
          </CancelDialog>
        )}
        {this.state.openScenarioLimit && (
          <PitchScenarioLimitDialog onClose={() => this.setState({ openScenarioLimit: false })} />
        )}
      </>
    );
  }
}

export default PitchScenarioMenu;
