import { BusinessDescription } from "./Prompts/BusinessOverview/Description.js";
import { BusinessIntelectualProperty } from "./Prompts/BusinessOverview/IntelectualProperty.js";
import { BusinessOwnership } from "./Prompts/BusinessOverview/Ownership.js";
import { BusinessProductsAndServices } from "./Prompts/BusinessOverview/ProductsAndServices.js";
import { BusinessValues } from "./Prompts/BusinessOverview/Values.js";
import { ExecutiveSummaryPrompt } from "./Prompts/ExecutiveSummary/ExecutiveSummary.js";
import { MarketCompetitors } from "./Prompts/MarketAnalysis/Competitors.js";
import { CriticalSuccessFactor } from "./Prompts/MarketAnalysis/CriticalSucessFactor.js";
import { MarketTrends } from "./Prompts/MarketAnalysis/MarketTrends.js";
import { MarketProblems } from "./Prompts/MarketAnalysis/Problems.js";
import { MarketSolutions } from "./Prompts/MarketAnalysis/Solutions.js";
import { TargetCustomers } from "./Prompts/MarketAnalysis/TargetCustomers.js";
import { MarketTarget } from "./Prompts/MarketAnalysis/TargetMarket.js";
import { UniqueSellingProposition } from "./Prompts/MarketAnalysis/UniqueSellingProposition.js";
import { Channels } from "./Prompts/Strategy/Channels.js";
import { CustomerJourney } from "./Prompts/Strategy/CustomerJourney.js";
import { Equipment } from "./Prompts/Strategy/Equipment.js";
import { KeyPersonnelTeam } from "./Prompts/Strategy/KeyPersonnelTeam.js";
import { ManagmentTeam } from "./Prompts/Strategy/ManagmentTeam.js";
import { Operations } from "./Prompts/Strategy/Operations.js";
import { Pricing } from "./Prompts/Strategy/Pricing.js";
import { Sales } from "./Prompts/Strategy/Sales.js";

export const SectionName = Object.freeze({
  BusinessDescription: "businessDescription",
  BusinessValues: "businessValues",
  BusinessOwnership: "businessOwnership",
  BusinessProductsAndServices: "businessProductsAndServices",
  BusinessIntellectualProperty: "businessIntellectualProperty",
  MarketProblems: "marketProblems",
  MarketSolutions: "marketSolutions",
  MarketTargets: "marketTargets",
  MarketTrends: "marketTrends",
  MarketCustomers: "marketCustomers",
  MarketCompetitors: "marketCompetitors",
  MarketCriticalSuccessFactor: "marketCriticalSuccessFactor",
  MarketSellingPropositions: "marketSellingPropositions",
  StrategyMarketChannels: "strategyMarketChannels",
  StrategyCustomerJourney: "strategyCustomerJourney",
  StrategyPricing: "strategyPricing",
  StrategySales: "strategySales",
  StrategyOperations: "strategyOperations",
  StrategyEquipment: "strategyEquipment",
  StrategyManagementTeam: "strategyManagementTeam",
  StrategyPersonnelTeam: "strategyPersonnelTeam",
});

const businessDescription = {
  index: 1,
  title: "Description",
  url: "description",
  prompt: <BusinessDescription />,
  name: SectionName.BusinessDescription,
};

const businessValues = {
  index: 2,
  title: "Our Values",
  url: "values",
  prompt: <BusinessValues />,
  name: SectionName.BusinessValues,
};

const businessOwnership = {
  index: 3,
  title: "Ownership",
  url: "ownership",
  prompt: <BusinessOwnership />,
  name: SectionName.BusinessOwnership,
};

const businessProductsServices = {
  index: 4,
  title: "Products & Services",
  url: "products_and_services",
  prompt: <BusinessProductsAndServices />,
  name: SectionName.BusinessProductsAndServices,
};

const businessIntelectualProperty = {
  index: 5,
  title: "Intellectual Property",
  url: "intelectual_property",
  prompt: <BusinessIntelectualProperty />,
  name: SectionName.BusinessIntellectualProperty,
};

const businessOverView = {
  index: 1,
  url: "business_overview",
  name: "Business Overview",
  sections: [
    businessDescription,
    businessValues,
    businessOwnership,
    businessProductsServices,
    businessIntelectualProperty,
  ],
  pageOrder: 3,
};

const marketProblemAndSolutions = {
  index: 1,
  title: "Problems & Solutions",
  url: "problems",
  subsections: [
    { title: "Problems", url: "problems", name: SectionName.MarketProblems },
    { title: "Solutions", url: "solutions", name: SectionName.MarketSolutions },
  ],
  name: [SectionName.MarketProblems, SectionName.MarketSolutions],
};

const marketProblems = {
  index: 1,
  title: "Problems",
  url: "problems",
  prompt: <MarketProblems />,
  name: SectionName.MarketProblems,
};

const marketSolutions = {
  index: 1,
  title: "Solutions",
  url: "solutions",
  prompt: <MarketSolutions />,
  name: SectionName.MarketSolutions,
};

const marketTargetMarket = {
  index: 2,
  title: "Target Market",
  url: "target_market",
  prompt: <MarketTarget />,
  name: SectionName.MarketTargets,
};

const marketTrends = {
  index: 3,
  title: "Market Trends",
  url: "market_trends",
  prompt: <MarketTrends />,
  name: SectionName.MarketTrends,
};

const marketTargetCustomers = {
  index: 4,
  title: "Target Customers",
  url: "target_customers",
  prompt: <TargetCustomers />,
  name: SectionName.MarketCustomers,
};

const marketCompetition = {
  index: 4,
  title: "Competition",
  url: "competitors",
  subsections: [
    { title: "Competitors", url: "competitors", name: SectionName.MarketCompetitors },
    {
      title: "Critical success factors",
      url: "critical_success_factors",
      name: SectionName.MarketCriticialSuccessFactor,
    },
    {
      title: "Unique selling proposition",
      url: "unique_selling_proposition",
      name: SectionName.MarketSellingPropositions,
    },
  ],
  name: [
    SectionName.MarketCompetitors,
    SectionName.MarketCriticialSuccessFactor,
    SectionName.MarketSellingPropositions,
  ],
};

const marketCompetitors = {
  index: 4,
  title: "Competitors",
  url: "competitors",
  prompt: <MarketCompetitors />,
  name: SectionName.MarketCompetitors,
};

const marketCriticalSuccessFactor = {
  index: 4,
  title: "Critical success factors",
  url: "critical_success_factors",
  prompt: <CriticalSuccessFactor />,
  name: SectionName.MarketCriticialSuccessFactor,
};

const marketUniqueSellingProposition = {
  index: 4,
  title: "Unique selling proposition",
  url: "unique_selling_proposition",
  prompt: <UniqueSellingProposition />,
  name: SectionName.MarketSellingPropositions,
};

const marketAnalysis = {
  index: 2,
  url: "market_analysis",
  name: "Market Analysis",
  sections: [
    marketProblemAndSolutions,
    marketTargetMarket,
    marketTrends,
    marketTargetCustomers,
    marketCompetition,
  ],
};

const marketing = {
  index: 1,
  title: "Marketing",
  url: "marketing_channels",
  subsections: [
    {
      title: "Marketing channels",
      url: "marketing_channels",
      name: SectionName.StrategyMarketChannels,
    },
    {
      title: "Customer journey",
      url: "customer_journey",
      name: SectionName.StrategyCustomerJourney,
    },
  ],
  name: [SectionName.StrategyMarketChannels, SectionName.StrategyCustomerJourney],
};

const marketingChannels = {
  index: 1,
  title: "Marketing channels",
  url: "marketing_channels",
  prompt: <Channels />,
  name: SectionName.StrategyMarketChannels,
};

const customerJourney = {
  index: 1,
  title: "Customer journey",
  url: "customer_journey",
  prompt: <CustomerJourney />,
  name: SectionName.StrategyCustomerJourney,
};

const pricing = {
  index: 2,
  title: "Pricing",
  url: "pricing",
  prompt: <Pricing />,
  name: SectionName.StrategyPricing,
};

const sales = {
  index: 3,
  title: "Sales",
  url: "sales",
  prompt: <Sales />,
  name: SectionName.StrategySales,
};

const operationsPage = {
  index: 1,
  title: "Operations",
  url: "Operations",
  subsections: [
    { title: "Operations", url: "operations", name: SectionName.StrategyOperations },
    { title: "Equipment", url: "equipment", name: SectionName.StrategyEquipment },
  ],
  name: [SectionName.StrategyOperations, SectionName.StrategyEquipment],
};

const operations = {
  index: 4,
  title: "Operations",
  url: "operations",
  prompt: <Operations />,
  name: SectionName.StrategyOperations,
};

const equipment = {
  index: 4,
  title: "Equipment",
  url: "equipment",
  prompt: <Equipment />,
  name: SectionName.StrategyEquipment,
};

const team = {
  index: 1,
  title: "Team",
  url: "managment_team",
  subsections: [
    { title: "Team", url: "managment_team", name: SectionName.StrategyManagementTeam },
    { title: "Key personnel team", url: "personnel_team", name: SectionName.StrategyPersonnelTeam },
  ],
  name: [SectionName.StrategyManagementTeam, SectionName.StrategyPersonnelTeam],
};

const managmentTeam = {
  index: 5,
  title: "Team",
  url: "managment_team",
  prompt: <ManagmentTeam />,
  name: SectionName.StrategyManagementTeam,
};

const keyPersonnelTeam = {
  index: 5,
  title: "Key personnel team",
  url: "personnel_team",
  prompt: <KeyPersonnelTeam />,
  name: SectionName.StrategyPersonnelTeam,
};

const strategy = {
  index: 3,
  name: "Strategy",
  url: "strategy",
  sections: [marketing, pricing, sales, operationsPage, team],
};

const financials = {
  index: 4,
  name: "Financials",
  icon: { icon: "icon-financials", text: "Visit Financial Forecast" },
};

const executiveSummarySection = {
  index: 1,
  title: "Executive Summary",
  url: "summary",
  prompt: <ExecutiveSummaryPrompt />,
};

const coverPage = {
  index: 2,
  title: "Cover Page",
  url: "cover_page",
  prompt: <BusinessDescription />,
};

const executiveSummary = {
  index: 5,
  name: "Executive Summary",
  url: "executive_summary",
  sections: [executiveSummarySection, coverPage],
};

const cards = [businessOverView, marketAnalysis, strategy, financials, executiveSummary];

export {
  cards,
  businessDescription,
  businessValues,
  businessOwnership,
  businessProductsServices,
  businessIntelectualProperty,
  marketProblems,
  marketSolutions,
  marketTargetMarket,
  marketTrends,
  marketTargetCustomers,
  marketCompetition,
  marketCompetitors,
  marketCriticalSuccessFactor,
  marketUniqueSellingProposition,
  marketing,
  pricing,
  sales,
  operations,
  managmentTeam,
  executiveSummarySection,
  coverPage,
  businessOverView,
  marketAnalysis,
  strategy,
  financials,
  executiveSummary,
  keyPersonnelTeam,
  marketingChannels,
  customerJourney,
  equipment,
  operationsPage,
};
