import { Button } from "./CopiedFromRefactor/Button";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import { usePage } from "../contexts/PageProvider";

export function SectionButtons(props) {
  const { onNext, onGenerate } = props;
  const { isLoading } = usePage();

  return (
    <div className="flex justify-center gap-11">
      <Button outline rounded onClick={onGenerate} className={"w-full max-w-96 py-4"}>
        {isLoading ? (
          <CircleLoader className={"h-8"} />
        ) : (
          <span className="text-24 font-bold min-h-8">Generate & Review Section</span>
        )}
      </Button>
      <Button rounded onClick={onNext} className={"w-full max-w-96"}>
        <span className="text-24 font-bold"> Next Section</span>
      </Button>
    </div>
  );
}
