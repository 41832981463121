import { sectionObjectToAiSectionDto } from "../models/aiSection";
import { saveInputs, saveSection } from "../Services/api/Repositories/BusinessPlanGenerator";

export function useSave({ sectionName }) {
  async function nextSection(generatedData) {
    await saveInputs(generatedData, {
      inputs: { ...generatedData },
    });

    await saveSection(
      sectionObjectToAiSectionDto({
        sectionName,
        shouldGenerate: false,
      }),
    );
  }

  async function generateSection(generatedData) {
    await saveInputs(generatedData, {
      inputs: { ...generatedData },
    });

    await saveSection(
      sectionObjectToAiSectionDto({
        sectionName,
        shouldGenerate: true,
      }),
    );
  }

  return {
    nextSection,
    generateSection,
  };
}
