import { useEffect, useState } from "react";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { CardSelect } from "../../components/CardSelect";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { Input } from "../../components/Input";
import { usePage } from "../../contexts/PageProvider";
import { SectionButtons } from "../../components/SectionButtons";
import { businessProductsAndServicesObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";

export function BusinessProductsAndServices() {
  const navigate = useNavigate();
  const {
    section,
    businessProductsAndServices,
    setBusinessProductsAndServices,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection } = useSave(sectionName);

  const [productCards, setProductCards] = useState([]);
  const [serviceCards, setServiceCards] = useState([]);
  const [productInputValue, setProductInputValue] = useState("");
  const [serviceInputValue, setServiceInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isProductInputActive, setIsProductInputActive] = useState(false);
  const [isServiceInputActive, setIsServiceInputActive] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      title: item,
      isSelected: selectedItems.includes(item),
    }));

    selectedItems.forEach((selectedItem) => {
      if (!items.includes(selectedItem)) {
        allItems.push({ title: selectedItem, isSelected: true });
      }
    });

    return allItems;
  }

  async function fetchProducts() {
    try {
      setLoadingProducts(true);
      const productResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "products",
      });
      setProductCards(transformToSelectable(productResponse.SectionData.Options.products));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoadingProducts(false);
    }
  }

  async function fetchServices() {
    try {
      setLoadingServices(true);
      const serviceResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "services",
      });
      setServiceCards(transformToSelectable(serviceResponse.SectionData.Options.services));
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoadingServices(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;

    if (businessProductsAndServices) {
      if (businessProductsAndServices.products) {
        setProductCards((prev) =>
          transformToSelectable(options?.products || prev, businessProductsAndServices.products),
        );
      }
      if (businessProductsAndServices.services) {
        setServiceCards((prev) =>
          transformToSelectable(options?.services || prev, businessProductsAndServices.services),
        );
      }
    }

    if (options) {
      if (!options.products) {
        fetchProducts();
      } else {
        setProductCards(
          transformToSelectable(options.products, businessProductsAndServices.products),
        );
      }
      if (!options.services) {
        fetchServices();
      } else {
        setServiceCards(
          transformToSelectable(options.services, businessProductsAndServices.services),
        );
      }
    }
  }, [options, businessProductsAndServices]);

  function handleInputChange(event, isProduct) {
    if (isProduct) {
      setProductInputValue(event.target.value);
    } else {
      setServiceInputValue(event.target.value);
    }
  }

  function addCard(isProduct = true) {
    let trimmedValue = isProduct ? productInputValue.trim() : serviceInputValue.trim();
    if (!trimmedValue) return;

    const cardExists = isProduct
      ? productCards.some((card) => card.title === trimmedValue)
      : serviceCards.some((card) => card.title === trimmedValue);

    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { title: trimmedValue, isSelected: false };
    if (isProduct) {
      setProductInputValue("");
      setIsProductInputActive(false);
      setProductCards((prevCards) => [...prevCards, newCard]);
    } else {
      setServiceInputValue("");
      setIsServiceInputActive(false);
      setServiceCards((prevCards) => [...prevCards, newCard]);
    }
    setInputError(false);
  }

  function handleKeyPress(event, isProduct) {
    if (event.key === "Enter") {
      addCard(isProduct);
    }
  }

  function onProductCardClick(index) {
    setProductCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, isSelected: !card.isSelected } : card)),
    );
  }

  function onServiceCardClick(index) {
    setServiceCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, isSelected: !card.isSelected } : card)),
    );
  }

  function handleSaveEdit(index, newTitle, isProduct = true) {
    if (isProduct) {
      setProductCards((prevCards) =>
        prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
      );
    } else {
      setServiceCards((prevCards) =>
        prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
      );
    }
  }

  function validate() {
    return (
      productCards.some((card) => card.isSelected === true) &&
      serviceCards.some((card) => card.isSelected === true)
    );
  }

  function onNextHandle() {
    setBusinessProductsAndServices({
      products: productCards.filter((card) => card.isSelected).map((card) => card.title),
      services: serviceCards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });
    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        setLoading(true);
        await nextSection(
          businessProductsAndServicesObjectToAiGeneratedContentDto(generatedData, sectionName),
        );
      } catch (error) {
        console.error("Error during business plan generation:", error);
      } finally {
        setLoading(false);
      }
    });

    navigate("/business_plan/ai_generator/business_overview/intelectual_property");
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    setBusinessProductsAndServices({
      products: productCards.filter((card) => card.isSelected).map((card) => card.title),
      services: serviceCards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });
    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        setLoading(true);
        await generateSection(
          businessProductsAndServicesObjectToAiGeneratedContentDto(generatedData, sectionName),
        );
      } catch (error) {
        console.error("Error during business plan generation:", error);
      } finally {
        setLoading(false);
      }
    });
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={productCards.some((card) => card.isSelected)}
        question={"What products will your business sell?"}
        description={
          "Define the products that you plan to sell. Services that you sell will be defined later."
        }
        hasSelectOptions
        onRegenerateOptions={() => fetchProducts()}
      >
        <div className="grid grid-cols-1 gap-3 auto-rows-fr">
          {loadingProducts ? (
            <CircleLoader />
          ) : (
            productCards.map((card, index) => (
              <CardSelect
                value={card.title}
                onClick={() => onProductCardClick(index)}
                key={`select-product-card-${index}`}
                isSelected={card.isSelected}
                onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue, true)}
                index={index}
              >
                {card.title}
              </CardSelect>
            ))
          )}

          {isProductInputActive && (
            <Input
              error={inputError}
              placeholder="Add new product"
              value={productInputValue}
              onChange={(e) => handleInputChange(e, true)}
              onKeyPress={(event) => handleKeyPress(event, true)}
            />
          )}
        </div>

        <Button className={"w-38"} rounded outline onClick={() => setIsProductInputActive(true)}>
          Add More
        </Button>
      </QuestionWrapper>

      <QuestionWrapper
        isAnswered={serviceCards.some((card) => card.isSelected)}
        question={"What services will your business provide?"}
        description={"Now define the services your business will offer."}
        hasSelectOptions
        onRegenerateOptions={() => fetchServices()}
      >
        <div className="grid grid-cols-1 gap-3 auto-rows-fr">
          {loadingServices ? (
            <CircleLoader />
          ) : (
            serviceCards.map((card, index) => (
              <CardSelect
                value={card.title}
                onClick={() => onServiceCardClick(index)}
                key={`select-service-card-${index}`}
                isSelected={card.isSelected}
                onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue, false)}
                index={index}
              >
                {card.title}
              </CardSelect>
            ))
          )}

          {isServiceInputActive && (
            <Input
              error={inputError}
              placeholder="Add new service"
              value={serviceInputValue}
              onChange={(e) => handleInputChange(e, false)}
              onKeyPress={(event) => handleKeyPress(event, false)}
            />
          )}
        </div>

        <Button className={"w-38"} rounded outline onClick={() => setIsServiceInputActive(true)}>
          Add More
        </Button>
      </QuestionWrapper>

      <SectionButtons
        onNext={onNextHandle}
        onGenerate={onGenerateHandle}
        onSave={() => nextSection(businessProductsAndServices)}
      />
    </>
  );
}
