export function AiGeneratedContent({
  businessDescription,
  businessValues,
  businessOwnership,
  businessProductsAndServices,
  businessIntellectualProperty,
  marketProblems,
  marketSolutions,
  marketTargets,
  marketTrends,
  marketCustomers,
  marketCompetitors,
  marketSellingPropositions,
  strategyMarketChannels,
  strategyCustomerJourney,
  strategyPricing,
  strategySales,
  strategyOperations,
  strategyEquipment,
  strategyManagementTeam,
  strategyPersonnelTeam,
  options,
}) {
  return {
    businessDescription,
    businessValues,
    businessOwnership,
    businessProductsAndServices,
    businessIntellectualProperty,
    marketProblems,
    marketSolutions,
    marketTargets,
    marketTrends,
    marketCustomers,
    marketCompetitors,
    marketSellingPropositions,
    strategyMarketChannels,
    strategyCustomerJourney,
    strategyPricing,
    strategySales,
    strategyOperations,
    strategyEquipment,
    strategyManagementTeam,
    strategyPersonnelTeam,
    options,
  };
}
