import { useState } from "react";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { CardSelect } from "../../components/CardSelect";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";

export function ExecutiveSummaryPrompt() {
  const { page } = usePage();
  const [selectedCard, setSelectedCard] = useState(null);

  function validate() {
    return selectedCard !== null;
  }

  const data = {};

  return (
    <>
      <QuestionWrapper
        isAnswered={selectedCard !== null}
        question={"Create your Executive Summary."}
        description={
          "The Executive Summary provides a concise view of your business plan, including a business description, your value proposition, explanation of the opportunity, the target market and customer and key financials."
        }
      >
        <div className="flex gap-14 justify-center">
          <CardSelect
            nonEditable
            onClick={() => setSelectedCard(0)}
            isSelected={selectedCard === 0}
          >
            <div className="flex flex-col gap-2 p-3 items-center justify-center max-w-36">
              {selectedCard === 0 ? (
                <img className="h-14 w-14" src="/icons/icon-view-executive-summary-selected.svg" />
              ) : (
                <img className="h-14 w-14" src="/icons/icon-view-executive-summary.svg" />
              )}
              <span
                className={`font-bold text-center ${selectedCard === 0 ? "text-white" : "text-primary"}`}
              >
                View & Edit Executive Summary
              </span>
            </div>
          </CardSelect>

          <CardSelect
            nonEditable
            onClick={() => setSelectedCard(1)}
            isSelected={selectedCard === 1}
          >
            <div className="flex flex-col gap-2 p-3 items-center justify-center max-w-36">
              {selectedCard === 1 ? (
                <img
                  className="h-14 w-14"
                  src="/icons/icon-generate-executive-summary-selected.svg"
                />
              ) : (
                <img className="h-14 w-14" src="/icons/icon-generate-executive-summary.svg" />
              )}
              <span
                className={`font-bold text-center ${selectedCard === 1 ? "text-white" : "text-primary"}`}
              >
                Generate Executive Summary
              </span>
            </div>
          </CardSelect>
        </div>
      </QuestionWrapper>
    </>
  );
}
