import { CheckedBox } from "./CheckedBox";

const selectIcon = <img src="/icons/icon-select.svg" />;
const regenerateIcon = <img src="/icons/icon-regenerate.svg" />;

export function QuestionWrapper(props) {
  const {
    question,
    description,
    children,
    isAnswered,
    hasSelectOptions,
    maxSelectiveOptions,
    onRegenerateOptions,
    isOptional,
  } = props;

  return (
    <div className="flex flex-col gap-6 rounded-2xl shadow p-7">
      <div className="flex gap-5 w-full">
        <CheckedBox isChecked={isAnswered} />
        <div className="flex flex-col gap-6 grow">
          {(question || description) && (
            <div className="flex flex-col gap-4">
              <span className="text-24 font-bold">
                {question} {isOptional && <span className="text-grey-dark-1">(optional)</span>}
              </span>
              <span className="text-18">{description}</span>
            </div>
          )}
          {(hasSelectOptions || maxSelectiveOptions || onRegenerateOptions) && (
            <div className="flex justify-between text-primary">
              {hasSelectOptions && (
                <div className="flex items-center gap-1">{selectIcon}Select options</div>
              )}
              {maxSelectiveOptions && (
                <div className="flex items-center gap-1">
                  {selectIcon}Select maximum {maxSelectiveOptions} options
                </div>
              )}
              {onRegenerateOptions && (
                <div
                  className="cursor-pointer flex items-center gap-1"
                  onClick={() => onRegenerateOptions()}
                >
                  {regenerateIcon}Regenerate options{" "}
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
