import { BusinessPlanCard } from "../components/BusinessPlanCard";
import { FormNavigation } from "../components/FormNavigation";
import { Header } from "../components/Header";
import { PromptWrapper } from "../components/PromptWrapper";
import { usePage } from "../contexts/PageProvider";
import { useEffect } from "react";

const NAVBAR_HEIGHT = "68px";
const NAVIGATION_HEIGHT = "68px";
const HEADER_HEIGHT = "174px";

export function PageWrapper(props) {
  const { page, section } = props;

  const { setPage, setSection } = usePage();

  function onBackClick() {
    console.log("Go Back");
  }
  function onSaveClick() {
    console.log("Save");
  }

  useEffect(() => {
    setPage(page);
    setSection(section);
  }, [page, section]);

  return (
    <div className="flex flex-col bg-white">
      <Header section={section} index={page.index} page={page.name} />
      <div
        className={`flex justify-between gap-5 py-12 px-10 2xl:px-36 items-start max-h-[100vh-${NAVBAR_HEIGHT}-${NAVIGATION_HEIGHT}-${HEADER_HEIGHT}]`}
      >
        <div className={`w-full max-w-6xl flex flex-col gap-12`}>
          <PromptWrapper>{section.prompt}</PromptWrapper>
        </div>
        <BusinessPlanCard
          isFormCard
          selectedSection={section.title}
          index={page.index}
          title={page.name}
          sections={page.sections}
          url={page.url}
        />
      </div>
      <FormNavigation onBack={onBackClick} onSave={onSaveClick} />
    </div>
  );
}
