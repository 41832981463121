import { useState, useEffect } from "react";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { CardSelect } from "../../components/CardSelect";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { useSave } from "../../hooks/useSave";
import { SectionMode } from "../../helpers/enums";
import { marketTargetsObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { useNavigate } from "react-router-dom";

const initialCards = ["City", "State", "Country", "International", "World-wide"];

export function MarketTarget() {
  const navigate = useNavigate();
  const { section, businessDescription, marketTargets, setMarketTargets, getGeneratedData } =
    usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection } = useSave(sectionName);

  const [cards, setCards] = useState(initialCards);
  const [cardInputValue, setCardInputValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [isInputActive, setIsInputActive] = useState(false);
  const [attemptedGenerate, setAttemptedGenerate] = useState(false);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  useEffect(() => {
    if (marketTargets?.targetMarketType) {
      const targetMarketType = marketTargets.targetMarketType;
      const selectedCardIndex = cards.findIndex((card) => card === targetMarketType);

      if (selectedCardIndex !== -1) {
        setSelectedCardIndex(selectedCardIndex);

        if (targetMarketType === "International") {
          setInputValue(marketTargets.targetMarketGeography || "");
        }
      } else {
        setCards((prevCards) => {
          const newCards = [...prevCards, targetMarketType];
          setSelectedCardIndex(newCards.length - 1);
          return newCards;
        });
      }
    }
  }, [marketTargets, cards]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
    setValidationError("");
  }

  function handleCardInputChange(event) {
    setCardInputValue(event.target.value);
    setValidationError("");
  }

  function addCard() {
    const trimmedCardValue = cardInputValue.trim();
    if (trimmedCardValue) {
      if (cards.includes(trimmedCardValue)) {
        setValidationError("This card already exists.");
      } else {
        const newCards = [...cards, trimmedCardValue];
        setCards(newCards);
        setSelectedCardIndex(newCards.length - 1);
        setCardInputValue("");
        setIsInputActive(false);
      }
    } else {
      setValidationError("Please enter a valid card name.");
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      addCard();
    }
  }

  function onCardClick(index) {
    setSelectedCardIndex(index);
  }

  function getSelectedValue() {
    const selectedCard = cards[selectedCardIndex];
    if (selectedCard === "City") return businessDescription.city;
    if (selectedCard === "State") return businessDescription.state;
    if (selectedCard === "Country") return businessDescription.country;
    if (selectedCard === "International") return inputValue;
    if (selectedCard === "World-wide") return "World-wide";
    return selectedCard;
  }

  function validate() {
    if (cards[selectedCardIndex] === "International") {
      return !!inputValue;
    }
    return selectedCardIndex !== null;
  }

  function onNextHandle() {
    if (!validate()) return;

    const selectedCard = cards[selectedCardIndex];
    const targetMarketGeography =
      selectedCard === "International" ? inputValue : getSelectedValue();

    setMarketTargets({
      targetMarketGeography,
      targetMarketType: selectedCard,
      mode: SectionMode.Edit,
    });

    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        await nextSection(marketTargetsObjectToAiGeneratedContentDto(generatedData, sectionName));
      } catch (error) {
        console.error("Error during business plan generation:", error);
      }
    });

    navigate("/business_plan/ai_generator/market_analysis/market_trends");
  }

  async function onGenerateHandle() {
    setAttemptedGenerate(true);

    if (!validate()) return;

    const selectedCard = cards[selectedCardIndex];
    const targetMarketGeography =
      selectedCard === "International" ? inputValue : getSelectedValue();

    setMarketTargets({
      targetMarketGeography,
      targetMarketType: selectedCard,
      mode: SectionMode.Edit,
    });

    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        await generateSection(
          marketTargetsObjectToAiGeneratedContentDto(generatedData, sectionName),
        );
      } catch (error) {
        console.error("Error during business plan generation:", error);
      }
    });
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={validate()}
        question={"What is your geographical target market?"}
        description={"Define where you sell your products and services."}
      >
        <div className="grid grid-cols-5 gap-3 auto-rows-fr">
          {cards.map((card, index) => (
            <CardSelect
              nonEditable
              onClick={() => onCardClick(index)}
              key={`select-card-${index}`}
              isSelected={selectedCardIndex === index}
            >
              {card}
            </CardSelect>
          ))}

          {isInputActive && (
            <Input
              error={validationError}
              placeholder="Business Value"
              className="text-center"
              value={cardInputValue}
              onChange={handleCardInputChange}
              onKeyPress={handleKeyPress}
            />
          )}
        </div>

        <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
          Add More
        </Button>
        {cards[selectedCardIndex] === "International" && (
          <Input
            error={
              attemptedGenerate && !inputValue
                ? "Please enter the countries where you sell your products."
                : ""
            }
            placeholder="USA, Canada, Germany"
            title="List countries where you sell your products."
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
        )}
      </QuestionWrapper>

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />
    </>
  );
}
