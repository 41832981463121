import { Component } from "react";
import { Route, Routes } from "react-router-dom";
import BusinessPlanUser from "./index";
import Preview from "./preview";
import { AiGenerator } from "../AiGenerator/AiGenerator";
import {
  businessDescription,
  businessOwnership,
  businessProductsServices,
  businessValues,
  businessIntelectualProperty,
  marketProblems,
  marketTargetMarket,
  marketTrends,
  marketTargetCustomers,
  pricing,
  sales,
  operations,
  managmentTeam,
  keyPersonnelTeam,
  executiveSummarySection,
  businessOverView,
  marketAnalysis,
  strategy,
  executiveSummary,
  marketSolutions,
  marketCriticalSuccessFactor,
  marketUniqueSellingProposition,
  marketCompetitors,
  marketingChannels,
  customerJourney,
  equipment,
} from "../AiGenerator/config";
import { PageWrapper } from "../AiGenerator/Prompts/PageWrapper";
import { PageProvider } from "../AiGenerator/contexts/PageProvider";

class BpRoutes extends Component {
  render() {
    return (
      <PageProvider>
        <Routes>
          <Route path="/" element={<BusinessPlanUser />}></Route>
          <Route path="/preview" element={<Preview />}></Route>
          <Route path="/ai_generator" element={<AiGenerator />}></Route>
          <Route
            path="/ai_generator/business_overview/description"
            element={<PageWrapper page={businessOverView} section={businessDescription} />}
          ></Route>
          <Route
            path="/ai_generator/business_overview/values"
            element={<PageWrapper page={businessOverView} section={businessValues} />}
          ></Route>
          <Route
            path="/ai_generator/business_overview/ownership"
            element={<PageWrapper page={businessOverView} section={businessOwnership} />}
          ></Route>
          <Route
            path="/ai_generator/business_overview/products_and_services"
            element={<PageWrapper page={businessOverView} section={businessProductsServices} />}
          ></Route>
          <Route
            path="/ai_generator/business_overview/intelectual_property"
            element={<PageWrapper page={businessOverView} section={businessIntelectualProperty} />}
          ></Route>
          <Route
            path="/ai_generator/market_analysis/problems"
            element={<PageWrapper page={marketAnalysis} section={marketProblems} />}
          ></Route>
          <Route
            path="/ai_generator/market_analysis/solutions"
            element={<PageWrapper page={marketAnalysis} section={marketSolutions} />}
          ></Route>
          <Route
            path="/ai_generator/market_analysis/solutions"
            element={<PageWrapper page={marketAnalysis} section={marketSolutions} />}
          ></Route>
          <Route
            path="/ai_generator/market_analysis/target_market"
            element={<PageWrapper page={marketAnalysis} section={marketTargetMarket} />}
          ></Route>
          <Route
            path="/ai_generator/market_analysis/market_trends"
            element={<PageWrapper page={marketAnalysis} section={marketTrends} />}
          ></Route>
          <Route
            path="/ai_generator/market_analysis/target_customers"
            element={<PageWrapper page={marketAnalysis} section={marketTargetCustomers} />}
          ></Route>
          <Route
            path="/ai_generator/market_analysis/competitors"
            element={<PageWrapper page={marketAnalysis} section={marketCompetitors} />}
          ></Route>
          <Route
            path="/ai_generator/market_analysis/critical_success_factors"
            element={<PageWrapper page={marketAnalysis} section={marketCriticalSuccessFactor} />}
          ></Route>
          <Route
            path="/ai_generator/market_analysis/unique_selling_proposition"
            element={<PageWrapper page={marketAnalysis} section={marketUniqueSellingProposition} />}
          ></Route>
          <Route
            path="/ai_generator/strategy/marketing_channels"
            element={<PageWrapper page={strategy} section={marketingChannels} />}
          ></Route>
          <Route
            path="/ai_generator/strategy/customer_journey"
            element={<PageWrapper page={strategy} section={customerJourney} />}
          ></Route>
          <Route
            path="/ai_generator/strategy/pricing"
            element={<PageWrapper page={strategy} section={pricing} />}
          ></Route>
          <Route
            path="/ai_generator/strategy/sales"
            element={<PageWrapper page={strategy} section={sales} />}
          ></Route>
          <Route
            path="/ai_generator/strategy/operations"
            element={<PageWrapper page={strategy} section={operations} />}
          ></Route>
          <Route
            path="/ai_generator/strategy/equipment"
            element={<PageWrapper page={strategy} section={equipment} />}
          ></Route>
          <Route
            path="/ai_generator/strategy/managment_team"
            element={<PageWrapper page={strategy} section={managmentTeam} />}
          ></Route>
          <Route
            path="/ai_generator/strategy/personnel_team"
            element={<PageWrapper page={strategy} section={keyPersonnelTeam} />}
          ></Route>
          <Route
            path="/ai_generator/executive_summary/summary"
            element={<PageWrapper page={executiveSummary} section={executiveSummarySection} />}
          ></Route>
        </Routes>
      </PageProvider>
    );
  }
}

export default BpRoutes;
