import { useEffect, useState } from "react";
import { Button } from "../../components/CopiedFromRefactor/Button";
import { CardSelect } from "../../components/CardSelect";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { Input } from "../../components/Input";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import { marketSolutionsObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import { fetchOptions } from "../../Services/api/Repositories/BusinessPlanGenerator";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";

const MAX_SELECTIONS = 5;

export function MarketSolutions() {
  const navigate = useNavigate();
  const {
    section,
    marketSolutions,
    setMarketSolutions,
    getGeneratedData,
    setLoading,
    isLoading,
    options,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection } = useSave(sectionName);

  const [solutionCards, setSolutionCards] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [loadingSolutions, setLoadingSolutions] = useState(false);

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  console.log("marketSolutions", marketSolutions);

  const bpScenarioId = global.Modeliks.BusinessPlanScenarioInfo.ID;

  function transformToSelectable(items = [], selectedItems = []) {
    const allItems = items.map((item) => ({
      title: item,
      isSelected: selectedItems.includes(item),
    }));

    selectedItems.forEach((selectedItem) => {
      if (!items.includes(selectedItem)) {
        allItems.push({ title: selectedItem, isSelected: true });
      }
    });

    return allItems;
  }

  async function fetchSolutions() {
    try {
      setLoadingSolutions(true);
      const solutionResponse = await fetchOptions({
        bpScenarioId,
        optionKey: "solutions",
      });
      setSolutionCards(transformToSelectable(solutionResponse.SectionData.Options.solutions));
    } catch (error) {
      console.error("Error fetching solutions:", error);
    } finally {
      setLoadingSolutions(false);
    }
  }

  useEffect(() => {
    if (isLoading) return;

    if (marketSolutions) {
      if (marketSolutions.solutions) {
        setSolutionCards((prev) =>
          transformToSelectable(options?.solutions || prev, marketSolutions.solutions),
        );
      }
    }

    if (options) {
      if (!options.solutions) {
        fetchSolutions();
      } else {
        setSolutionCards(transformToSelectable(options.solutions, marketSolutions.solutions));
      }
    }
  }, [options, marketSolutions, isLoading]);

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function addCard() {
    const trimmedValue = inputValue.trim();
    if (!trimmedValue) return;

    const cardExists = solutionCards.some((card) => card.title === trimmedValue);
    if (cardExists) {
      setInputError(true);
      return;
    }

    const newCard = { title: trimmedValue, isSelected: false };
    setSolutionCards((prevCards) => [...prevCards, newCard]);
    setInputValue("");
    setIsInputActive(false);
    setInputError(false);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      addCard();
    }
  }

  function onCardClick(index) {
    const selectedCount = solutionCards.filter((card) => card.isSelected).length;

    setSolutionCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index
          ? {
              ...card,
              isSelected: card.isSelected ? false : selectedCount < MAX_SELECTIONS,
            }
          : card,
      ),
    );
  }

  function handleSaveEdit(index, newTitle) {
    setSolutionCards((prevCards) =>
      prevCards.map((card, i) => (i === index ? { ...card, title: newTitle } : card)),
    );
  }

  function validate() {
    return solutionCards.filter((card) => card.isSelected).length > 0;
  }

  function onNextHandle() {
    setMarketSolutions({
      solutions: solutionCards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });
    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        await nextSection(marketSolutionsObjectToAiGeneratedContentDto(generatedData, sectionName));
      } catch (error) {
        console.error("Error during business plan generation:", error);
      }
    });
    navigate("/business_plan/ai_generator/market_analysis/target_market");
  }

  async function onGenerateHandle() {
    if (!validate()) return;

    setMarketSolutions({
      solutions: solutionCards.filter((card) => card.isSelected).map((card) => card.title),
      mode: SectionMode.Edit,
    });
    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        await generateSection(
          marketSolutionsObjectToAiGeneratedContentDto(generatedData, sectionName),
        );
      } catch (error) {
        console.error("Error during business plan generation:", error);
      }
    });
  }

  return (
    <>
      <QuestionWrapper
        isAnswered={solutionCards.some((card) => card.isSelected)}
        question={"How does your business solve these problems?"}
        description={
          "Explain how your business solves each problem and the benefits that customers get when using your product or service."
        }
        maxSelectiveOptions={5}
        onRegenerateOptions={fetchSolutions}
      >
        {loadingSolutions ? (
          <CircleLoader />
        ) : (
          <div className="grid grid-cols-2 gap-3 auto-rows-fr">
            {solutionCards.map((card, index) => (
              <CardSelect
                value={card.title}
                onClick={() => onCardClick(index)}
                key={`select-card-${index}`}
                isSelected={card.isSelected}
                onSaveEdit={(i, newValue) => handleSaveEdit(i, newValue)}
                index={index}
              >
                {card.title}
              </CardSelect>
            ))}

            {isInputActive && (
              <Input
                error={inputError}
                placeholder="Add new solution"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
            )}
          </div>
        )}

        <Button className={"w-38"} rounded outline onClick={() => setIsInputActive(true)}>
          Add More
        </Button>
      </QuestionWrapper>

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />
    </>
  );
}
