export function Header(props) {
  const { section, index, page } = props;
  return (
    <div className="w-full h-fit bg-blue-light-4 flex gap-9 py-10 pl-36">
      <div className="flex items-end gap-1 mb-1">
        <img className="h-full max-h-16 min-w-12" src={`/icons/icon-${index}.svg`} />
        <div className="w-6 h-6 min-w-6 min-h-6 border-2 border-primary rounded-full"></div>
        <img className="h-full max-h-16 min-w-12" src={`/icons/icon-${section.index}.svg`} />
      </div>
      <div className="flex flex-col">
        <span className="text-60 font-bold leading-[70px]">{section.title}</span>
        <span className="text-grey-dark-2">{page}</span>
      </div>
    </div>
  );
}
