import { useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { QuestionWrapper } from "../../components/QuestionWrapper";
import { SectionButtons } from "../../components/SectionButtons";
import { usePage } from "../../contexts/PageProvider";
import { SectionMode } from "../../helpers/enums";
import { useSave } from "../../hooks/useSave";
import { businessDescriptionObjectToAiGeneratedContentDto } from "../../models/aiGeneratedContent/transformer";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { useNavigate } from "react-router-dom";

export function BusinessDescription() {
  const navigate = useNavigate();
  const {
    section,
    businessDescription,
    setBusinessDescription,
    getGeneratedData,
    setLoading,
    isLoading,
  } = usePage();

  const [sectionName, setSectionName] = useState({ sectionName: "" });
  const { nextSection, generateSection } = useSave(sectionName);

  const [companyName, setCompanyName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    if (section) {
      setSectionName({ sectionName: section.name });
    }
  }, [section]);

  useEffect(() => {
    if (businessDescription !== null) {
      setCompanyName(businessDescription.companyName ?? "");
      setIndustryName(businessDescription.industryName ?? "");
      setCity(businessDescription.city ?? "");
      setState(businessDescription.state ?? "");
      setCountry(businessDescription.country ?? "");
      setMonth(businessDescription.monthEstablished ?? "");
      setYear(businessDescription.yearEstablished ?? "");
    }
  }, [businessDescription]);

  const [errors, setErrors] = useState({
    companyName: false,
    industryName: false,
    city: false,
    state: false,
    country: false,
    month: false,
    year: false,
  });

  function validate() {
    const newErrors = {
      companyName: !companyName,
      industryName: !industryName,
      city: !city,
      state: !state,
      country: !country,
      month: !month,
      year: !year,
    };

    setErrors(newErrors);
    return !Object.values(newErrors).includes(true);
  }

  const handleChange = (setter, fieldName) => (e) => {
    setter(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: false }));
  };

  function onNextHandle() {
    setBusinessDescription({
      companyName,
      industryName,
      city,
      state,
      country,
      monthEstablished: month,
      yearEstablished: year,
      mode: SectionMode.Edit,
    });
    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        await nextSection(
          businessDescriptionObjectToAiGeneratedContentDto(generatedData),
          sectionName,
        );
      } catch (error) {
        console.error("Error during business plan generation:", error);
      }
    });
    navigate("/business_plan/ai_generator/business_overview/values");
  }

  async function onGenerateHandle() {
    if (!validate()) return;
    setBusinessDescription({
      companyName,
      industryName,
      city,
      state,
      country,
      monthEstablished: month,
      yearEstablished: year,
      mode: SectionMode.Edit,
    });
    setTimeout(async () => {
      const generatedData = getGeneratedData();
      try {
        await generateSection(
          businessDescriptionObjectToAiGeneratedContentDto(generatedData),
          sectionName,
        );
      } catch (error) {
        console.error("Error during business plan generation:", error);
      }
    });
  }

  return (
    <>
      {isLoading ? (
        <CircleLoader />
      ) : (
        <>
          {" "}
          <QuestionWrapper
            isAnswered={!!companyName}
            question={"What is the business called?"}
            description={
              "If you don’t have a name yet, give it a temporary name and change it later."
            }
          >
            <Input
              placeholder="Company name"
              value={companyName}
              onChange={handleChange(setCompanyName, "companyName")}
              error={errors.companyName}
            />
          </QuestionWrapper>
          <QuestionWrapper
            isAnswered={!!industryName}
            question={"What does the business do?"}
            description={
              "Keep this short like digital marketing agency, food truck, financial advisory, etc."
            }
          >
            <Input
              placeholder="Industry name"
              value={industryName}
              onChange={handleChange(setIndustryName, "industryName")}
              error={errors.industryName}
            />
          </QuestionWrapper>
          <QuestionWrapper
            isAnswered={!!city && !!state && !!country}
            question={"Where is the business located?"}
            description={
              "If in multiple locations, provide the city, state, and country of the head office."
            }
          >
            <div className="grid grid-cols-3 gap-3">
              <Input
                placeholder="City"
                value={city}
                onChange={handleChange(setCity, "city")}
                error={errors.city}
              />
              <Input
                placeholder="State"
                value={state}
                onChange={handleChange(setState, "state")}
                error={errors.state}
              />
              <Input
                placeholder="Country"
                value={country}
                onChange={handleChange(setCountry, "country")}
                error={errors.country}
              />
            </div>
          </QuestionWrapper>
          <QuestionWrapper
            isAnswered={!!month && !!year}
            question={"When was the business established?"}
            description={"If not established yet, tell us when you plan to establish it."}
          >
            <div className="grid grid-cols-2 gap-3">
              <Input
                placeholder="Month"
                value={month}
                onChange={handleChange(setMonth, "month")}
                error={errors.month}
              />
              <Input
                placeholder="Year"
                value={year}
                type={"number"}
                onChange={handleChange(setYear, "year")}
                error={errors.year}
              />
            </div>
          </QuestionWrapper>
        </>
      )}

      <SectionButtons onNext={onNextHandle} onGenerate={onGenerateHandle} />
    </>
  );
}
